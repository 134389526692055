@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
}

:root{
    --color1: #292929;
    --color2: white;
    --color3: #59DE21;
}

#app{
    min-height:100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--color1);
    color: var(--color2);
    position: relative;
}

main{
    flex:1;
    margin-top:20px;
    margin-bottom: 20px;
}

/*<Header>*/

nav{
    display: flex;
    align-items: center;
    padding:10px;
    justify-content: space-around;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.35) ;
    height:100%;
}

nav #links{
    display: none;
    gap: 10px;
    align-items: center;
}

nav #links.clicked{
    display: flex;
    position: absolute;
    flex-direction: column;
    top:0;
    right:0;
    background-color: var(--color1);
    width:100%;
    height:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

nav #links.clicked a{
    width: 50%;
    border:1px solid;
}

nav #links a{
    text-decoration: none;
    padding:10px 20px;
    border-radius: 5px;
    color:var(--color2);
}

nav #links a:hover{
    text-decoration: underline;
}

.landing .left h1{
    line-height: 40px;
}

nav .leftnavcontainer{
    display: flex;
    align-items: center;
    gap:10px;
    z-index:10;
    cursor: pointer;
}

nav .logo{
    font-size:30px;
}

nav .hamburger{
    font-size:40px;
    z-index:10;
}

nav div span{
    display: block;
    text-align: center;
}    

nav div #pennypack{
    font-weight: 700;
    font-size:24px;
}

nav div #webdesigns{
    text-transform: uppercase;
    font-weight:500;
    letter-spacing: 3px;
}

/*</Header>*/


/*<Home> */

.landing{
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap:40px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.landing h1{
    font-size:35px;
    max-width: 500px;
}

.landing .left p{
    max-width: 550px;
}

.landing a{
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    padding:10px 20px;
    text-decoration: none;
    color:white;
}

.landing img{
    width: 250px;
    border-radius: 10px;
}

.landing .left, .right{
    display: flex;
    flex-direction: column;
    gap:20px;
}

.landing .left{
    justify-content: center;
    align-items: center;
}


/* </Home> */


/* <About > */

.about #content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap:30px;
}


.about #content .left, .right{
    padding:0px 10px;
    text-align: center;
    gap:15px;
    margin-bottom: 25px;
    align-items: center;
}


.about #content img{
    width: 200px;
    border-radius: 10px;
}

.about #content h1{
    text-align: center;
    font-size: 32px;
}

.about #content a{
    text-decoration: none;
    color:white;
    padding:10px 15px;
    border-radius: 5px;
    max-width: 200px;
}


.about #content p{
    max-width: 600px;
}


/* </About> */



/* <Services >*/

.services .content h1{
    font-size: 32px;
}

.services .content{
    text-align: center;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: center;
}

.services .content .benefits{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.services .content .benefits .benefit{
    display: flex;
    gap:10px;
    align-items: center;
}


.services .content a{
    text-decoration: none;
    color:white;
    padding:10px 20px;
    border-radius: 5px;
    display: inline-block;
}

.services .content #allfor{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services .content .service-card{
    background-color: transparent;
    padding: 0px 20px;
    width:75%;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
}

.services .content .service-card h1{
    color: white;
    text-decoration: underline;
}

.services .content .service-card .price{
    color: white;
    margin-top: auto;
    margin-bottom: 20px;
}

.services .content .service-card ul{
    color: var(--color1);
}

.services .content .service-card .benefit{
    display: flex;
    align-items: center;
    text-align: left;
    gap:10px;
    margin-top:10px;
}







/* </Services> */



/* <Contact > */


.contact .container h1{
    font-size: 32px;
    text-align: center;
}

.contact .container {
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    gap:15px;
}

.contact .container form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:15px;
    justify-content: center;
}


.contact .container form input, textarea{
    border: 1px solid;
    border-radius: 5px;
    padding:5px;    
    width: 250px;      
    border:0;
}

.contact .container form textarea{
    height:125px;   
}

.contact .container form button{
    padding: 10px 20px;
    border:none;
    border-radius: 5px;
    background-color: var(--color1);
    color:white;
    cursor: pointer;
}



/* </Contact > */


/* <Footer> */


footer .content{
    padding:10px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items:center;
    background-color: var(--color1);
    box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.35) ;
}

footer .content .logo{
    display: flex;
    gap: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;    
}


footer .content #copyright{
    font-size:9px;
}

/* </Footer> */


/* <Testimonial>*/

.testimonial .testimonialcontainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap:15px;
    width: 70%;
    margin: 0 auto;
}

.testimonial .testimonialcontainer .stars{
    display: flex;
}

.testimonial .testimonialcontainer .stars img{
    width: 20px;
}

.testimonial .testimonialcontainer .testimonialauthor{
    font-style: italic !important;
    opacity: 0.75 !important;
}

/* </Testimonial>*/


/* UTIL */
.uppercase{
    text-transform: uppercase;
}

.underline{
    text-decoration: underline;
}


.hoverbtn{
    border:2px solid var(--color2) !important;
    transition: 0.15s all ease !important;
}

.hoverbtn:hover{
    color: var(--color2) !important;
    text-decoration: none !important;
}

.hidden{
    display: none;
}


/* ENDUTIL */




@media (min-width:1024px)
{
    nav #links{
        display: flex;
    }

    nav .hamburger{
        display: none;
    }

    .landing{
        gap:70px;
    }

    p{
        font-size:21px;
    }

    .landing .left h1{
        font-size:80px;
        line-height: 70px;
    }
    

    .contact .container form input, textarea{
        font-size:18px;
        width: 500px;
    }

    footer .content{
        font-size:20px;
    }

    footer .content #copyright{
        font-size:12px;
    }

    .services .content .service-card{
        max-width: 25%;
    }

    .testimonial .testimonialcontainer{
        margin-top:75px !important;
    }
}

@media (min-width: 768px)
{
    .landing{
        flex-direction: row;
    }

    .landing img{
        width: 300px;
    }

    .about #content {
        flex-direction: row;
    }

    .about #content img{
        width: 250px;
    }

    .services .content .service-card{
        width: 50%;
    }

    .testimonial .testimonialcontainer{
        margin-top:35px;
    }
}

@media(min-width: 481px)
{
    .landing h1{
        font-size:40px;
    }
}

@media(min-height:700px)
{
    main{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media(min-height:1024px)
{
    p{
        font-size:21px;
    }
}